import config from 'env'

export default {
  API_ENDPOINT: config.API_ENDPOINT,
  WS_ENDPOINT: config.WS_ENDPOINT,
  MAPBOX_TOKEN: config.MAPBOX_TOKEN,
  GOOGLE_TOKEN: config.GOOGLE_TOKEN,
  FB_APP_ID: config.FB_APP_ID,
  FB_APP_VERSION: config.FB_APP_VERSION
}
