
import hexToRgba from 'hex-to-rgba'

export const SYSTEM_PREFIX_PROPS = 'ease-x#'
export const SYSTEM_PREFIX_MULTI_LAYER = 'properties#'

// layer types
// https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#type
// current support: fill, line, circle

// data types
// current support: color, number, string
export const STYLE2D_KEYS = {
  CIRCLE_COLOR: {
    key: `${SYSTEM_PREFIX_PROPS}circle-color`,
    defaultValue: hexToRgba('#FF7043'),
    layerType: 'circle',
    dataType: 'color'
  },
  CIRCLE_RADIUS: {
    key: `${SYSTEM_PREFIX_PROPS}circle-radius`,
    defaultValue: 4,
    layerType: 'circle',
    dataType: 'number',
    attrs: {
      step: 1,
      min: 1,
      max: 1000
    }
  },
  FILL_COLOR: {
    key: `${SYSTEM_PREFIX_PROPS}fill-color`,
    defaultValue: hexToRgba('#1B96B0'),
    layerType: 'fill',
    dataType: 'color'
  },
  // FILL_OUTLINE_COLOR: {
  //   key: `${SYSTEM_PREFIX_PROPS}fill-outline-color`,
  //   defaultValue: hexToRgba('#1989A0'),
  //   layerType: 'fill',
  //   dataType: 'color'
  // },
  FILL_OPACITY: {
    key: `${SYSTEM_PREFIX_PROPS}fill-opacity`,
    defaultValue: 0.5,
    layerType: 'fill',
    dataType: 'number',
    attrs: {
      step: 0.1,
      min: 0,
      max: 1
    }
  },
  LINE_COLOR: {
    key: `${SYSTEM_PREFIX_PROPS}line-color`,
    defaultValue: hexToRgba('#03A1FC'),
    layerType: ['line', 'fill'],
    dataType: 'color'
  },
  LINE_WIDTH: {
    key: `${SYSTEM_PREFIX_PROPS}line-width`,
    defaultValue: 4,
    layerType: ['line', 'fill'],
    dataType: 'number',
    attrs: {
      step: 1,
      min: 1,
      max: 1000
    }
  },
  LINE_DASHARRAY: {
    key: `${SYSTEM_PREFIX_PROPS}line-dasharray`,
    defaultValue: 0,
    layerType: ['line', 'fill'],
    dataType: 'select',
    options: [
      {
        text: 'solid_line',
        value: 0
      },
      {
        text: 'dashed_line',
        value: 1
      },
      {
        text: 'solid_dashed_line',
        value: 2
      },
      {
        text: 'solid_double_dashed_line',
        value: 3
      }
    ]
  }
}

// multi layer properties設計
// feature = {
//   properties: {
//     // style2d properties
//   },
//   properties#<layer label>: {
//     // user general properties
//   }
// }
export const FEATURE_MULTI_LAYER = {
  key: `${SYSTEM_PREFIX_PROPS}multi-layer`,
  is(feature) {
    // check feautre is multi layer
    return Boolean(feature?.properties?.[this.key])
  },
  getLength(feature) {
    if (!this.is(feature)) return

    return Object.keys(feature.properties)
      .filter(key => key.startsWith(SYSTEM_PREFIX_MULTI_LAYER))
      .length
  },
  getLayerProperties(feature) {
    // 將feature下 properties#<layer label> 整理成array回傳
    if (!this.is(feature)) return []

    return Object.keys(feature.properties)
      .filter(key => key.startsWith(SYSTEM_PREFIX_MULTI_LAYER))
      .map(key => {
        // 不知道哪邊來的properties#<layer label>會是string
        const layerProperties = typeof feature.properties[key] === 'string'
          ? JSON.parse(feature.properties[key])
          : feature.properties[key]

        return [
          key, // 完整的key name
          layerProperties, // 該layer的properties
          key.replace(SYSTEM_PREFIX_MULTI_LAYER, '') // layer label
        ]
      })
      // .sort((a, b) => a[0].localeCompare(b[0], undefined, { numeric: true }))
  },
  getLayerPropertiesObject(feature) {
    if (!this.is(feature)) return {}

    return Object.fromEntries(this.getLayerProperties(feature))
  },
  getTemplateProperties(feature) {
    if (!feature) {
      return {}
    }

    if (!this.is(feature)) return feature.properties

    const style2dProperties = feature.properties
    const layerProperties = Object.fromEntries(
      this.getLayerProperties(feature).flatMap(layer => Object.entries(layer[1]))
    )

    return {
      ...style2dProperties,
      ...layerProperties
    }
  },
  getLayerIndexByLayerLabel(feature, layerLabel) {
    if (!this.is(feature)) {
      return
    }

    return this.getLayerProperties(feature).findIndex(layer => layer[2] === layerLabel)
  },
  defaultValue: false,
  dataType: 'boolean'
}
