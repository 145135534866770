import Vue from 'vue'
import VueRouter from 'vue-router'

import i18n, { mapNavigatorLanguage } from '@/i18n'
import store from '@/store'

Vue.use(VueRouter)

const App = () =>
  import(/* webpackChunkName: "App" */ '@/App')
const Login = () =>
  import(/* webpackChunkName: "Login" */ '@/views/SignInUp/Login')
const Logout = () =>
  import(/* webpackChunkName: "Logout" */ '@/views/SignInUp/Logout')
const SignUp = () =>
  import(/* webpackChunkName: "SignUp" */ '@/views/SignInUp/SignUp')
const Verify = () =>
  import(/* webpackChunkName: "Verify" */ '@/views/SignInUp/Verify')
const ForgotPwd = () =>
  import(/* webpackChunkName: "ForgotPwd" */ '@/views/SignInUp/ForgotPwd')
const ResetPwd = () =>
  import(/* webpackChunkName: "ResetPwd" */ '@/views/SignInUp/ResetPwd')

const Map = () => import(/* webpackChunkName: "Map" */ '@/views/Map/Map')
const MapBar = () => import(/* webpackChunkName: "MapBar" */ '@/components/Map/Bars/MapBar')
const ViewerBar = () => import(/* webpackChunkName: "ViewerBar" */ '@/components/Map/Bars/ViewerBar')
const PostprocessBar = () => import(/* webpackChunkName: "PostprocessBar" */ '@/components/Map/Bars/PostprocessBar')
const DrawerBar = () => import(/* webpackChunkName: "DrawerBar" */ '@/components/Map/Bars/DrawerBar')

const Portal = () =>
  import(/* webpackChunkName: "Portal" */ '@/views/Portal/Portal')
const Projects = () =>
  import(
    /* webpackChunkName: "Projects" */ '@/views/Portal/ProjectMgr/Projects'
  )
const Teams = () =>
  import(/* webpackChunkName: "Teams" */ '@/views/Portal/TeamMgr/Teams')
const FileMgr = () =>
  import(/* webpackChunkName: "FileMgr" */ '@/views/Portal/FileMgr/FileMgr')
const Databases = () =>
  import(
    /* webpackChunkName: "Databases" */ '@/views/Portal/DatabaseMgr/Databases'
  )
const Dashboards = () =>
  import(
    /* webpackChunkName: "Dashboards" */ '@/views/Portal/ProjectMgr/DashboardMgr/Dashboards'
  )
const Dashboard = () =>
  import(
    /* webpackChunkName: "Dashboard" */ '@/views/Portal/ProjectMgr/DashboardMgr/Dashboard'
  )
const Panels = () =>
  import(
    /* webpackChunkName: "Panels" */ '@/views/Portal/ProjectMgr/DashboardMgr/PanelMgr/Panels'
  )
const PanelAdder = () =>
  import(
    /* webpackChunkName: "PanelAdder" */ '@/components/PanelAdder/PanelAdder'
  )

const routes = [
  {
    path: '/',
    name: 'root'
    // redirect in beforeEach to check lang
  },
  {
    path: '/verify/:code',
    name: 'Verify',
    component: Verify,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/reset-password/:code?',
    name: 'ResetPwd',
    component: ResetPwd,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/:lang',
    component: App,
    meta: {
      isPublic: true
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login,
        meta: {
          isPublic: true
        }
      },
      {
        path: 'logout',
        name: 'Logout',
        component: Logout,
        meta: {
          isPublic: true
        }
      },
      {
        path: 'signup',
        name: 'SignUp',
        component: SignUp,
        meta: {
          isPublic: true
        }
      },
      {
        path: 'forgot-password',
        name: 'ForgotPwd',
        component: ForgotPwd,
        meta: {
          isPublic: true
        }
      },
      {
        path: 'map/:projectId?',
        component: Map,
        children: [
          {
            path: '',
            name: 'Map',
            components: {
              nav: MapBar
            }
          },
          {
            path: 'viewer',
            name: 'MapViewer',
            components: {
              nav: ViewerBar
            },
            meta: {
              liteModel: true
            }
          },
          {
            path: 'postprocess',
            name: 'MapPostprocess',
            components: {
              nav: PostprocessBar
            }
          },
          {
            path: 'drawer',
            name: 'MapDrawer',
            components: {
              nav: DrawerBar
            },
            meta: {
              liteModel: true
            }
          }
        ]
      },
      {
        // Nav 使用 EaseAppBar
        path: '/:lang',
        name: 'Portal',
        component: Portal,
        redirect: to => ({
          name: 'Projects',
          params: to.params,
          query: to.query
        }),
        children: [
          {
            path: 'projects',
            name: 'Projects',
            component: Projects
          },
          {
            path: 'teams',
            name: 'Teams',
            component: Teams
          },
          {
            path: 'files',
            name: 'FileMgr',
            component: FileMgr
          },
          {
            path: 'databases',
            name: 'Databases',
            component: Databases
          },
          {
            path: 'projects/:projectId?/dashboards', // 圖表管理
            name: 'Dashboards',
            component: Dashboards
          }
        ]
      },
      {
        // Nav 使用 簡化的 v-app-bar
        path: 'projects/:projectId/dashboards/:dashboardId',
        name: 'Dashboard',
        component: Dashboard,
        redirect: {
          name: 'Panels'
        },
        children: [
          {
            path: 'panels',
            name: 'Panels',
            component: Panels
          },
          {
            path: 'panel-adder',
            name: 'PanelAdder',
            component: PanelAdder,
            meta: {
              hideFooter: true,
              toPanels: true
            }
          },
          {
            path: 'panel-editor/:panelId',
            name: 'PanelEditor',
            component: PanelAdder,
            meta: {
              hideFooter: true,
              toPanels: true
            }
          }
        ]
      }

    ]
  },
  {
    path: '*',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (
    from.name !== 'Login' &&
    to.name !== 'Verify' &&
    to.name !== 'ResetPwd' &&
    (to.name === 'root' ||
     !to.params.lang)
  ) {
    return next({
      name: 'Login',
      params: {
        ...to.params,
        lang: mapNavigatorLanguage(window.navigator.language)
      },
      query: to.query
    })
  }

  // check loginToken
  if (to.matched.every(matched => matched.meta.isPublic)) {
    return next()
  }

  const loginToken = localStorage.getItem('loginToken')
  if (!loginToken) {
    store.dispatch('snackbar/showError', {
      content: i18n.t('api_errors.unauthorized')
    })

    store.dispatch('init')

    return next({
      name: 'Login',
      query: { redirect: to.fullPath }
    })
  }
  next()
})

export default router
