import { Node } from './Node'
import { Source } from '../Source'
import { SIM_MODEL } from '@/models'
import {
  setProperties,
  FILE_TYPE,
  getGeojsonLayerStyleByType,
  SETTINGS_KEYS
} from '@/models/utils'

import { cloneDeep } from 'lodash'
import dotObj from 'dot-object'

import i18n from '@/i18n'

const { PARENT_VTK } = SETTINGS_KEYS

const threeDFileTypes = ['glb', 'vtk', 'gltf']

const d2Settings = Object.fromEntries(
  // ['FLYTO_CENTER', 'FLYTO_ZOOM']
  []
    .map(key => {
      const SETTINGS_KEY = SETTINGS_KEYS[key]

      return [SETTINGS_KEY.key, SETTINGS_KEY.defaultValue]
    })
)
const d3Settings = Object.fromEntries(
  // ['D3_CENTER', 'SKY_AXIS', 'FLYTO_CENTER', 'FLYTO_ZOOM']
  ['D3_CENTER', 'SKY_AXIS', 'PARENT_VTK']
    .map(key => {
      const SETTINGS_KEY = SETTINGS_KEYS[key]

      return [SETTINGS_KEY.key, SETTINGS_KEY.defaultValue]
    })
)

export class FileNode extends Node {
  type=''
  name=''
  s3Uuid=''
  createdTime
  modifiedTime
  size
  fileContent
  stagingFile
  maplayerType
  simulationModel
  parentMaplayerUuid
  isLoading=false // Boolean | error String, 讀取children或fileContent中
  isLoadingMetadata=false // Boolean, 讀取metadata中
  hasLoaded=false // 資料夾是判斷是否載入過children, 檔案則是判斷是否載入到圖層管理
  isDownloading=false
  isUploading=false // 後端紀錄的屬性, 表示正在上傳檔案內容中
  isBinary=false // 後端紀錄的屬性, 表示是FILE_TYPE.FILE
  downloadProgress=0
  settings={}
  maxBreadNameLength=24

  constructor(uuid, config = {}) {
    const {
      type,
      name,
      s3Uuid,
      createdTime,
      modifiedTime,
      size,
      fileContent,
      settings,
      isUploading,
      isBinary,
      maplayerType,
      simulationModel,
      parentMaplayerUuid,
      ...nodeConfig
    } = config

    super(uuid, nodeConfig)

    setProperties(this, {
      type: type ?? this.type,
      name: name ?? this.name,
      s3Uuid: s3Uuid ?? this.s3Uuid,
      createdTime: createdTime ?? this.createdTime,
      modifiedTime: modifiedTime ?? this.modifiedTime,
      size: size ?? this.size,
      isUploading: isUploading ?? isUploading,
      isBinary: isBinary ?? isBinary,
      maplayerType: maplayerType ?? maplayerType,
      simulationModel: simulationModel ?? simulationModel,
      parentMaplayerUuid: parentMaplayerUuid ?? parentMaplayerUuid
    })

    if (this.type === FILE_TYPE.FILE) {
      delete this.children
    }

    // 設定settings預設的keys, 使用對應的defaultValue
    switch (this.fileType) {
      case 'glb':
      case 'vtk':
      case 'gltf':
        setProperties(this.settings, {
          plot: {},
          meshes: [],
          ...cloneDeep(d3Settings)
        })
        if (simulationModel === SIM_MODEL.MASSBALANCE) {
          setProperties(this.settings, {
            [SETTINGS_KEYS.MASSBALANCE.key]: SETTINGS_KEYS.MASSBALANCE.defaultValue
          })
        }
        break
      default:
        setProperties(this.settings, cloneDeep(d2Settings))
    }

    fileContent && this.setFileContent(fileContent)
    settings && this.readSettings(settings)
  }

  // getter/setter
  get fileType() {
    /**
     * 取得檔案類型 (小寫)
     * @returns String
    **/

    if (
      this.type === FILE_TYPE.FOLDER ||
       this.type === FILE_TYPE.MAPSET
    ) {
      return FILE_TYPE.FOLDER.toLowerCase()
    }

    if (!this.name) return

    const names = this.name.split('.')

    return names.pop()?.toLowerCase()
  }

  get fileName() {
    /**
     * 取得檔案名稱 不包含檔案類型
     * @returns String
    **/

    if (
      this.type === FILE_TYPE.FOLDER ||
       this.type === FILE_TYPE.MAPSET
    ) {
      return this.name
    }

    if (!this.name) return

    const names = this.name.split('.')

    return names.slice(0, -1).join('.')
  }

  get is3DFile() {
    /**
     * 判斷檔案是3D類型檔案
     * @returns Boolean
    **/

    return threeDFileTypes.indexOf(this.fileType) !== -1
  }

  get isVtkFile() {
    /**
     * 判斷檔案是vtk類型檔案
     * @returns Boolean
    **/

    return this.fileType === 'vtk'
  }

  get isDamagedFile() {
    return !this.isBinary && !this.isUploading
  }

  get parentVtk() {
    return this.settings[PARENT_VTK.key]
  }

  get breadcrumbs() {
    const parents = [this]
    let nextParent = this.parent
    while (nextParent) {
      const notRoot = !!nextParent.type // 用是否存在name判斷會更好, 但目前允許創建沒有name的檔案/資料夾
      if (notRoot) {
        parents.unshift(nextParent)
      }
      nextParent = nextParent.parent
    }
    const helfMaxLength = this.maxBreadNameLength / 2
    return parents.map(fileNode => {
      let name = fileNode.name
      if (name.length > this.maxBreadNameLength) {
        name = `${name.slice(0, helfMaxLength)}...${name.slice(-helfMaxLength)}`
      }
      return name
    })
  }

  // methods
  setSize(size) {
    /**
     * 設定檔案大小, 目前使用s3Metadata內的ContentLength
     * @param {Number} size - 檔案bytes
     * @returns self
    **/

    this.size = size ?? this.size

    return this
  }

  readSettings(settings) {
    /**
     * 合併settings
     * NOTE: 要是新舊settings key型別不同, dotObj會以舊的為主, 所以要刪掉舊的
     * @param {Object} settings - settings內的value可為任意, 會幫忙try JSON.parse
     * @returns self
    **/

    if (!settings) {
      return
    }

    const curSettings = cloneDeep(dotObj.dot(this.settings))
    const newSettings = cloneDeep(dotObj.dot(settings))

    this.setProperties({
      settings: dotObj.object({
        ...curSettings,
        ...newSettings
      })
    })

    return this

    // const targetSettingKeys = Object.keys(this.settings)
    // setProperties(this.settings, Object.fromEntries(
    //   Object.entries(settings)
    //     .filter(([key]) => targetSettingKeys.includes(key))
    //     .map(([key, value]) => [key, cloneDeep(value)])
    // ))
  }

  checkFileSettings() {
    if (!this.is3DFile) {
      return
    }

    const { D3_CENTER, SKY_AXIS } = SETTINGS_KEYS
    const d3Center = this.settings[D3_CENTER.key]
      ?.filter(ll => ll != null && ll !== '')
    if (!d3Center || d3Center.length < 2) {
      this.setProperties({
        isLoading: i18n.t('load_faild')
      })
      return new Error(i18n.t('page.map.error_settings_center_coord'))
    }

    const skyAxis = this.settings[SKY_AXIS.key]
    if (!SKY_AXIS.options.map(o => o.value).includes(skyAxis)) {
      this.setProperties({
        isLoading: i18n.t('load_faild')
      })
      return new Error(i18n.t('page.map.error_settings_sky_axis'))
    }
  }

  setFileContent(fileContent) {
    /**
     * @param {Object, Blob} fileContent
     * @returns self
    **/

    this.setProperties({
      fileContent
    })

    return this
  }

  setStagingFile(stagingFile) {
    /** 允許設為null
     * @param {StagingFile} StagingFile
     * @returns self
    **/

    this.setProperties({
      stagingFile
    })

    return this
  }

  toLayerNodeFormate() {
    /**
     * FileNode 轉換成 LayerNode 需要的 layerNodeConfig
     * @returns layerNodeConfig | layer3DNodeConfig
    **/

    const { D3_CENTER } = SETTINGS_KEYS

    // Layer3DNode constroucture config
    // const fileContent = (this.stagingFile && this.stagingFile.fileContent) || this.fileContent
    if (this.is3DFile) {
      return {
        ...this,
        type: 'custom',
        blob: this.fileContent,
        center: this.settings[D3_CENTER.key]
      }
    }

    // LayerNode constroucture config
    const geoJsonSource = {
      type: 'geojson',
      data: this.fileContent,
      generateId: true
    }

    const source = new Source(this.uuid, { data: geoJsonSource })

    const layerType = source.geoJsonData.features?.[0]?.geometry?.type
    const layerStyle = getGeojsonLayerStyleByType(layerType)

    return {
      ...this,
      ...layerStyle,
      source
    }
  }
}
