import camelize from 'camelize'
import i18n from '@/i18n'
import dot from 'dot-object'

import {
  apiGrafanaDashboards,
  apiInfluxMeasurements,
  apiS3MaplayerData
} from '@/api'

import {
  createPanel,
  createTarget,
  createOverride,
  formatQuery,
  newPanelIdByDashboard
} from '@/assets/js/grafana/model'

import { FEATURE_MULTI_LAYER } from '@/models/utils'

const formatMeasurements = measurements => {
  if (!Array.isArray(measurements)) return []

  // return measurements.map(measurement => measurement.measurement_name)
  return measurements
}

const findNextId = ids => {
  if (!Array.isArray(ids) || ids.length === 0) {
    return 1
  }

  if (ids.length === 1) {
    const [id] = ids

    return (id > 1 && 1) || id + 1
  }

  const sortedIds = ids.slice().sort((a, b) => a - b)

  if (sortedIds[0] > 1) return 1

  return (
    sortedIds.find((id, iId, arr) => {
      const nextId = arr[iId + 1]
      if (!nextId) return true
      return nextId - id > 1
    }) + 1
  )
}

const graphs = () => [
  {
    text: i18n.t('panel_time_series'),
    value: 'timeseries',
    src: require('@/assets/images/chart-bar.png'),
    sources: [
      {
        src: require('@/assets/images/chart-bar.webp'),
        type: 'image/webp'
      },
      {
        src: require('@/assets/images/chart-bar.png'),
        type: 'image/png'
      }
    ]
  },
  {
    text: i18n.t('panel_singlestat'),
    value: 'singlestat',
    src: require('@/assets/images/chart-line.png'),
    sources: [
      {
        src: require('@/assets/images/chart-line.webp'),
        type: 'image/webp'
      },
      {
        src: require('@/assets/images/chart-line.png'),
        type: 'image/png'
      }
    ]
  },
  {
    text: i18n.t('panel_table'),
    value: 'table',
    src: require('@/assets/images/chart-table.png'),
    sources: [
      {
        src: require('@/assets/images/chart-table.webp'),
        type: 'image/webp'
      },
      {
        src: require('@/assets/images/chart-table.png'),
        type: 'image/png'
      }
    ]
  }
]

const state = () => ({
  step: 1,
  stepPreview: 2,
  formData: createPanel(),
  measurements: [],
  tagKeys: [],
  isLoadingMeasurements: false,
  isLoadingTags: false,
  snapshotUrl: ''
})

const getters = {
  graphs,
  targetNames: state => {
    return state.formData?.targets?.map?.(target => target.refId) || []
  },
  getNewPanel: (state, _, __, rootGetters) => (dashboard, projectId) => {
    const newPanel = JSON.parse(JSON.stringify(state.formData))

    // 沒有id是新增panel, 有id是編輯panel
    if (!newPanel.id) {
      newPanel.id = newPanelIdByDashboard(dashboard)
    }
    // if (!newPanel.datasource && projectId) {
    if (typeof newPanel.datasource !== 'object' && projectId) {
      const project = rootGetters?.['projects/getProjectById']?.(projectId)
      newPanel.datasource = {
        type: 'influxdb',
        // uid: project?.influxBucketName || ''
        uid: 'Xa3mED3Gk',
        influxBucketName: project?.influxBucketName || ''
      }
      // newPanel.datasource = project?.influxBucketName || ''
    }

    // format target query data string
    newPanel.targets.forEach(target => {
      target.query = formatQuery({
        influxBucketName: newPanel.datasource?.influxBucketName || newPanel.datasource,
        target
      })

      if (target.properties) {
        delete target.properties.color

        let override = newPanel.fieldConfig.overrides.find(o => o.matcher.options === target.refId)

        if (!override) {
          override = {
            matcher: {
              id: 'byFrameRefID',
              options: target.refId
            },
            properties: []
          }
          newPanel.fieldConfig.overrides.push(override)
        }

        override.properties = []
        if (target.properties.custom.lineStyle) {
          override.properties = [
            {
              id: 'custom.lineStyle',
              value: target.properties.custom.lineStyle
            }
          ]

          delete target.properties.custom.lineStyle
        }

        override.properties = [
          ...override.properties,
          ...Object.entries(dot.dot(target.properties)).map(([id, value]) => ({
            id,
            value
          }))
        ]

        delete target.properties
      }
    })

    return newPanel
  }
}

const actions = {
  init: ({ state: storeState, commit }) => {
    commit('setState', {
      ...state(),
      measurements: storeState.measurements // measurements只該隨project改變
    })
  },

  setState: ({ commit }, payload) => {
    commit('setState', payload)
  },

  setFormData: ({ commit }, payload) => {
    commit('setFormData', payload)
  },

  editPanel: ({ commit, state: curState }, { panel } = {}) => {
    if (!panel) return

    commit('setState', {
      ...state(),
      measurements: curState.measurements, // measurements只該隨project改變
      formData: JSON.parse(JSON.stringify(panel))
    })
  },
  addTarget: ({ state, commit, getters }) => {
    const untitleIds = getters.targetNames
      .filter(name => name.startsWith('Untitle Data '))
      .map(name => parseInt(name.replace('Untitle Data ', '')))
      .filter(id => !isNaN(id))

    const index = findNextId(untitleIds)
    const [firstMeasurement] = state.measurements

    const target = createTarget(`Untitle Data ${index}`, {
      measurement: firstMeasurement?.measurementName || ''
    })

    commit('addTarget', target)
  },
  removeTarget: ({ commit }, target) => {
    if (!target) return

    commit('removeTarget', target)
  },
  copyTarget: ({ getters, commit }, target) => {
    const copyTargetIds = getters.targetNames
      .filter(name => name.startsWith(`${target.refId} copy `))
      .map(name => parseInt(name.replace(`${target.refId} copy `, '')))
      .filter(id => !isNaN(id))

    const index = findNextId(copyTargetIds)

    let copyTarget = null
    try {
      copyTarget = JSON.parse(JSON.stringify(target))
    } catch (error) {}

    const newTargetName = `${target.refId} copy ${index}`
    /* eslint-disable */
    const newTarget = copyTarget
      ? {
          ...copyTarget,
          refId: newTargetName
        }
      : createTarget(newTargetName)
    /* eslint-enable */

    commit('addTarget', newTarget)
  },

  fetchMeasurements: ({ commit }, { project } = {}) => {
    if (!project) return

    const { uuid: projectId, influxBucketId } = project

    if (!projectId || !influxBucketId) return

    commit('setState', {
      isLoadingMeasurements: true
    })
    return apiInfluxMeasurements.get(influxBucketId, projectId)
      .then(res => {
        const measurements = formatMeasurements(camelize(res?.data?.data))

        commit('setState', {
          measurements
        })
      })
      .finally(() => {
        commit('setState', {
          isLoadingMeasurements: false
        })
      })
  },
  fetchTags: ({ commit }, { projectId, dashboard } = {}) => {
    if (!projectId || !dashboard) return Promise.resolve()

    const resourceId = dashboard.maplayer_resource_uuid

    // dashboard 沒綁定檔案的case
    if (!resourceId) return Promise.resolve()

    commit('setState', {
      isLoadingTags: true
    })

    const params = {
      project_uuid: projectId,
      resource_uuid: resourceId
    }
    return apiS3MaplayerData.get(params)
      .then(res => {
        const firstFeature = res.data?.features?.[0]
        const firstFeatureProperties = FEATURE_MULTI_LAYER.getTemplateProperties(firstFeature)

        const tagKeys = Object.keys(firstFeatureProperties)

        commit('setState', {
          tagKeys: (Array.isArray(tagKeys) && tagKeys) || []
        })
      })
      .finally(() => {
        commit('setState', {
          isLoadingTags: false
        })
      })
  },

  submit: async ({ getters }, { dashboard: oriDashboard, projectId } = {}) => {
    if (!oriDashboard || !projectId) return

    const {
      resourceId: dashboardResourceId,
      maplayer_resource_uuid: maplayerResourceUuid,
      ...dashboard
    } = oriDashboard
    delete dashboard.time

    const newPanel = getters.getNewPanel(dashboard, projectId)

    const formData = {
      payload: {
        dashboard: {
          ...dashboard,
          panels: [
            newPanel,
            ...dashboard.panels.filter(panel => panel.id !== newPanel.id)
          ]
        }
      },
      maplayer_resource_uuid: maplayerResourceUuid,
      project_uuid: projectId,
      resource_uuid: dashboardResourceId
    }

    return apiGrafanaDashboards.put(formData)
  }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  },
  setFormData: (state, payload) => {
    Object.assign(state.formData, payload)
  },
  addTarget: (state, target) => {
    state.formData.targets.push(target)

    state.formData.fieldConfig.overrides.push(createOverride(target))
  },
  removeTarget: (state, target) => {
    state.formData.targets = state.formData.targets.filter(t => t !== target)

    const indexOverride = state.formData.fieldConfig.overrides.findIndex(o => o.matcher.options === target.refId)
    if (indexOverride > -1) {
      state.formData.fieldConfig.overrides.splice(indexOverride, 1)
    }
  }
}

export const form = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
