import { STYLE2D_KEYS } from '@/models/utils/source'

const {
  CIRCLE_COLOR,
  CIRCLE_RADIUS,
  LINE_WIDTH,
  LINE_COLOR,
  LINE_DASHARRAY,
  FILL_COLOR,
  // FILL_OUTLINE_COLOR,
  FILL_OPACITY
} = STYLE2D_KEYS

const expressionDesaturateColor = (keyColorProp, defaultVal) => [
  'let',
  'rgba', ['to-rgba', ['to-color', ['get', keyColorProp], defaultVal]],

  ['let',
    'r', ['number', ['at', 0, ['var', 'rgba']]],
    'g', ['number', ['at', 1, ['var', 'rgba']]],
    'b', ['number', ['at', 2, ['var', 'rgba']]],
    'a', ['number', ['at', 3, ['var', 'rgba']]],
    ['let',
      'avg', ['+', ['*', 0.299, ['var', 'r']], ['*', 0.587, ['var', 'g']], ['*', 0.114, ['var', 'b']]],
      ['let',
        'desat_r', ['+', ['*', 0.4, ['var', 'avg']], ['*', 0.4, 128], ['*', 0.2, ['var', 'r']]],
        'desat_g', ['+', ['*', 0.4, ['var', 'avg']], ['*', 0.4, 128], ['*', 0.2, ['var', 'g']]],
        'desat_b', ['+', ['*', 0.4, ['var', 'avg']], ['*', 0.4, 128], ['*', 0.2, ['var', 'b']]],
        ['rgba', ['var', 'desat_r'], ['var', 'desat_g'], ['var', 'desat_b'], ['var', 'a']]
      ]
    ]
  ]
]

const circle = () => ({
  type: 'circle',
  paint: {
    // 'circle-radius': {
    //   base: 1.75,
    //   stops: [
    //     [2, 4],
    //     [20, 40]
    //   ]
    // },
    'circle-radius': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      [
        '*',
        [
          'number',
          ['get', CIRCLE_RADIUS.key],
          CIRCLE_RADIUS.defaultValue
        ],
        1.5
      ],
      [
        'number',
        ['get', CIRCLE_RADIUS.key],
        CIRCLE_RADIUS.defaultValue
      ]
    ],
    'circle-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      expressionDesaturateColor(CIRCLE_COLOR.key, CIRCLE_COLOR.defaultValue),
      // pSBC(-0.5, CIRCLE_COLOR.defaultValue), // hover color
      [
        'to-color',
        ['get', CIRCLE_COLOR.key],
        CIRCLE_COLOR.defaultValue
      ]
    ]
  }
})

const line = () => ({
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
  paint: {
    'line-width': ['number',
      ['get', LINE_WIDTH.key],
      LINE_WIDTH.defaultValue
    ],
    'line-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      expressionDesaturateColor(LINE_COLOR.key, LINE_COLOR.defaultValue),
      // pSBC(-0.5, LINE_COLOR.defaultValue), // hover color
      [
        'to-color',
        ['get', LINE_COLOR.key],
        LINE_COLOR.defaultValue
      ]
    ],
    'line-dasharray': [
      'match',
      ['get', LINE_DASHARRAY.key],
      0, ['literal', [1, 0]],
      1, ['literal', [2, 2]],
      2, ['literal', [1, 2, 4, 2]],
      3, ['literal', [1, 2, 1, 2, 8, 2]],
      ['literal', [1, 0]]
    ]
  }
})

const fill = () => ({
  type: 'fill',
  layout: {},
  paint: {
    'fill-color': ['to-color',
      ['get', FILL_COLOR.key],
      FILL_COLOR.defaultValue
    ],
    // 'fill-outline-color': ['to-color',
    //   ['get', FILL_OUTLINE_COLOR.key],
    //   FILL_OUTLINE_COLOR.defaultValue
    // ],
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      [ // opacity + (1 - opacity) / 2
        '+',
        [
          'number',
          ['get', FILL_OPACITY.key],
          FILL_OPACITY.defaultValue
        ],
        [
          '/',
          [
            'number',
            [
              '-',
              1,
              [
                'number',
                ['get', FILL_OPACITY.key],
                FILL_OPACITY.defaultValue
              ]
            ]
          ],
          2
        ]

      ],
      [
        'number',
        ['get', FILL_OPACITY.key],
        FILL_OPACITY.defaultValue
      ]
    ]
  }
})

export const layerTypes = [
  'fill', 'line', 'symbol', 'circle',
  'heatmap', 'fill-extrusion', 'raster',
  'hillshade', 'background', 'sky', 'custom'
] // 用來判斷layerNode是否為mapbox layer // 排除folder, mapset, file

export const getGeojsonLayerStyleByType = type => {
  const targetType = type.toLowerCase()

  switch (targetType) {
    case 'point':
    case 'multipoint':
      return circle()
    case 'linestring':
    case 'multilinestring':
      return line()
    case 'polygon':
    case 'multipolygon':
      return fill()
  }

  console.debug(`${type} layer style not found`)
}
