import { form } from './dashboard-form'

import { apiGrafanaDashboards } from '@/api'

import { getDefaultPanelFieldConfig } from '@/assets/js/grafana/model'

const formatDashboards = dashboards => {
  if (!Array.isArray(dashboards)) return []

  return dashboards
    .map(({ json, resource, maplayer_resource: mapLayerResource }) => {
      const dashboard = json?.dashboard

      if (!dashboard) return

      delete dashboard.uid

      return {
        ...dashboard,
        panels: formatPanels(dashboard?.panels),
        panelCount: dashboard?.panels?.length || 0,
        resourceId: resource?.uuid,
        maplayer_resource_uuid: mapLayerResource?.uuid || null
      }
    })
    .filter(Boolean)
}

const formatPanels = panels => {
  if (!Array.isArray(panels)) return []

  return panels.map(panel => {
    if (!Object.prototype.hasOwnProperty.call(panel, 'fieldConfig')) {
      panel.fieldConfig = getDefaultPanelFieldConfig(panel.targets)

      if (panel.type === 'graph') {
        panel.type = 'timeseries'
      }

      delete panel.lines
      delete panel.linewidth
    }

    // Migrate tag.isTemplate to tag.isGeojsonVariable
    panel.targets.forEach(target => {
      target.tags.forEach(tag => {
        if (Object.prototype.hasOwnProperty.call(tag, 'isTemplate')) {
          tag.isGeojsonVariable = tag.isTemplate

          delete tag.isTemplate
        }
      })
    })

    return panel
  })
}

const state = () => ({
  dashboards: [],
  isLoadingDashboards: false
})

const getters = {
  getDashboardsByMaplayerId: state => maplayerId => {
    return state.dashboards.filter(
      dashboard => dashboard.maplayer_resource_uuid === maplayerId
    )
  },
  getDashboardByRoute: state => route => {
    const dashboardId = route.params.dashboardId

    return state.dashboards.find(
      dashboard => dashboard.resourceId === dashboardId
    )
  },
  getPanelsByRoute: (_, getters) => route => {
    const dashboard = getters.getDashboardByRoute(route)

    const panels = dashboard?.panels

    return (Array.isArray(panels) && panels) || []
  },
  getPanelByRoute: (_, getters) => route => {
    const panels = getters.getPanelsByRoute(route)
    const panelId = route.params.panelId

    return panels.find(panel => `${panel.id}` === `${panelId}`)
  }
}

const actions = {
  init: ({ commit, dispatch }) => {
    dispatch('form/init')
    commit('setState', state())
  },
  fetchDashboards: async (
    { commit },
    { project = {}, loading = true } = {}
  ) => {
    const { uuid: projectId, grafanaId } = project

    if (!projectId || !grafanaId) return

    commit('setState', {
      isLoadingDashboards: loading
    })
    return apiGrafanaDashboards.get(grafanaId, projectId)
      .then(res => {
        const dashboards = formatDashboards(res?.data?.data)

        commit('setState', {
          dashboards
        })
      })
      .finally(() => {
        commit('setState', {
          isLoadingDashboards: false
        })
      })
  },
  fetchDashboard: (
    { commit, state },
    { projectId, dashboardId, loading = true } = {}
  ) => {
    // if (!dashboardId) return
    commit('setState', {
      isLoadingDashboards: loading && dashboardId
    })
    return apiGrafanaDashboards.get(dashboardId, projectId, false)
      .then(res => {
        const [dashboard] = formatDashboards(res?.data?.data)

        const oriDashboard = state.dashboards.find(
          d => d.resourceId === dashboard.resourceId
        )

        if (oriDashboard) {
          commit('setState', {
            dashboards: state.dashboards.map(d =>
              d === oriDashboard ? dashboard : d
            )
          })
        } else {
          commit('setState', {
            dashboards: [dashboard, ...state.dashboards].sort((a, b) =>
              a.title.localeCompare(b.title)
            )
          })
        }
      })
      .finally(() => {
        commit('setState', {
          isLoadingDashboards: false
        })
      })
  },
  deleteDashboard: (_, { projectId, dashboard } = {}) => {
    if (!dashboard) return

    return apiGrafanaDashboards.delete({
      project_uuid: projectId,
      resource_uuid: dashboard.resourceId
    })
  }
}

const mutations = {
  setState: (state, payload = {}) => {
    Object.assign(state, payload)
  }
}

export const dashboards = {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
  modules: {
    form
  }
}
