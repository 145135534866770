import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { draggableDialog } from './draggableDialog'
import { draggableSnackbar } from './draggableSnackbar'
import { draggableMenu } from './draggableMenu'
import { mapNavigatorLanguage } from '@/i18n'

// Translation provided by Vuetify (javascript)
import zhHant from 'vuetify/es5/locale/zh-Hant'
import zhHans from 'vuetify/es5/locale/zh-Hans'
import en from 'vuetify/es5/locale/en'
// Your own translation file
import sv from '@/i18n/vuetify/zh-Hans.json'

import { FILE_ICONS } from './customIcons'

import '@mdi/font/css/materialdesignicons.min.css'

Vue.use(Vuetify)

draggableDialog('draggable')
draggableSnackbar('snack-draggable')
draggableMenu('menu-draggable')

export const mapI18nLocale = i18nLocale => {
  const locale = i18nLocale.toLowerCase()
  switch (locale) {
    case 'zh-tw':
      return 'zhHant'
    case 'zh-cn':
      return 'zhHans'
    case 'en':
      return 'en'
  }
}

export default new Vuetify({
  lang: {
    locales: { zhHant, zhHans, en, sv },
    current: mapI18nLocale(mapNavigatorLanguage(window.navigator.language))
  },
  theme: {
    themes: {
      light: {
        primary: '#1b7ec2',
        secondary: '#00abeb'
      }
    }
  },
  icons: {
    iconfont: 'mdi',
    values: {
      ...FILE_ICONS
    }
  }
})
